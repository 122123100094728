import { Controller } from "@hotwired/stimulus"
import * as THREE from "three"

export default class extends Controller {
  static values = { direction: Number, z: Number }

  connect() {
    const canvasBox = this.element.getBoundingClientRect()
    this.scene = new THREE.Scene()
    this.camera = new THREE.PerspectiveCamera(33, canvasBox.width / canvasBox.height, 0.1, 1000)

    this.renderer = new THREE.WebGLRenderer({
      antialias: true,
    })
    this.renderer.setSize(canvasBox.width, canvasBox.height)
    this.renderer.setClearColor(0xffffff, 1)
    this.element.appendChild(this.renderer.domElement)

    const cylinderGeometry = new THREE.CylinderGeometry(1, 1, 0.43, 64, 1, true)

    const id = this.element.id
    const map = new THREE.TextureLoader().load(`/assets/img/${id}.png`)
    const material = new THREE.MeshStandardMaterial({
      map,
      transparent: true,
    })
    material.side = THREE.DoubleSide
    this.orbit = new THREE.Mesh(cylinderGeometry, material)

    const pointLight = new THREE.PointLight(0xffffff)
    pointLight.position.set(0, 0, 0)
    this.scene.add(pointLight)

    const ambientLight = new THREE.AmbientLight(0xeeeeee)
    this.scene.add(ambientLight)

    const group = new THREE.Group()
    group.add(this.orbit)

    this.scene.add(group)

    group.rotation.x = -0.3
    group.rotation.z = this.zValue || -0.1
    group.position.y = -0.2

    this.camera.position.z = 2
    this.camera.position.x = 0
    this.camera.position.y = 0

    this.renderer.render(this.scene, this.camera)
    this.animateCallback = this.animate.bind(this)
    this.animate()
  }

  animate() {
    window.requestAnimationFrame(this.animateCallback)
    const rect = this.element.getBoundingClientRect()
    if (rect.top >= 0 && rect.bottom <= (window.innerHeight || document.documentElement.clientHeight)) {
      this.orbit.rotation.y += (this.directionValue || 1.0) * 0.0025
      this.renderer.render(this.scene, this.camera)
    }
  }
}
