import { Controller } from "@hotwired/stimulus"
import { gsap } from "gsap"

export default class extends Controller {
  static targets = ["text"]

  connect() {
    gsap.fromTo(
      this.textTarget,
      { scale: 2, opacity: 0.1 },
      {
        // letterSpacing: 1,
        opacity: 1,
        scale: 1,
        scrollTrigger: {
          trigger: this.element,
          start: "top center",
          end: "top top",
          scrub: 1,
        },
      },
    )
  }
}
