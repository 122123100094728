import { gsap } from "gsap"
import { ScrollTrigger } from "gsap/ScrollTrigger"
import { ScrollToPlugin } from "gsap/ScrollToPlugin"

gsap.registerPlugin(ScrollTrigger, ScrollToPlugin)

const menuTpl = document.getElementById("menuTpl")
const menuContainer = document.getElementById("menu-container")
const menuReveal = gsap
  .timeline({
    paused: true,
    defaults: {
      ease: "power2.inOut",
    },
  })
  .add(
    gsap.fromTo(
      "#menu-reveal",
      {
        width: "0vmin",
        height: "0vmin",
        left: () => 30,
        top: () => 30,
      },
      {
        left: "50%",
        top: "50%",
        width: "10vmax",
        height: "10vmax",
        duration: 1,
      },
    ),
  )
  .add(
    gsap.to("#menu-reveal", {
      width: "150vmax",
      height: "150vmax",
      duration: 1,
    }),
    "<+=.5",
  )
  .add(
    gsap.fromTo(
      "#reveal-items > *",
      {
        opacity: 0,
      },
      {
        opacity: 1,
        stagger: 0.1,
      },
    ),
    "<+=.1",
  )
  .eventCallback("onStart", () => {
    document.body.parentNode.classList.add("menuopen")
  })
  .eventCallback("onReverseComplete", () => {
    document.body.parentNode.classList.remove("menuopen")
    gsap.to(window, { scrollTo: goTo, duration: 2, ease: "power2.inOut" })
  })

// Snapping logic — disabled for now
// if (window.matchMedia("(min-width: 1024px)").matches) {
//   let timer = null
//   const snaps = [...document.querySelectorAll(".with-menu")]
//   addEventListener(
//     "scroll",
//     () => {
//       if (timer !== null) {
//         clearTimeout(timer)
//       }
//       timer = setTimeout(function () {
//         for (const el of snaps) {
//           const rect = el.getBoundingClientRect()
//           const t = rect.height * 0.15
//           const top = Math.floor(rect.top)
//           if ((top < -1 && top > -t) || (top > 1 && top < t)) {
//             gsap.to(window, {
//               scrollTo: el,
//               duration: 0.3,
//               ease: "power2.out",
//             })
//             return
//           }
//         }
//       }, 300)
//     },
//     false,
//   )
// }

let menuOpen = false
let goTo = undefined
let buttonAnimation = undefined
function toggleMenu(_goTo, _buttonAnimation, container) {
  menuOpen = !menuOpen
  goTo = _goTo
  if (_buttonAnimation) {
    buttonAnimation = _buttonAnimation
  }

  if (menuOpen) {
    menuReveal.duration(2)
    buttonAnimation.restart()

    menuContainer.querySelectorAll(`nav a`).forEach((el) => {
      el.classList.remove("active")
    })
    menuContainer.querySelector(`nav a[href="#${container.id}"]`)?.classList?.add("active")
    setTimeout(() => {
      const e = document.querySelector(`#menu-previews img[data-menu-target="#${container.id}"]`)
      if (e) {
        e.style.opacity = 1
      }
    }, 2000)
    menuReveal.restart()
  } else {
    document.querySelectorAll("#menu-previews img").forEach((el) => (el.style.opacity = 0))
    menuReveal.duration(1)
    buttonAnimation.reverse()
    menuReveal.reverse()
  }
}
const menu = document.querySelector("#menuTpl")
if (window.matchMedia("(max-width: 1023px)").matches && menu) {
  const top = menu.querySelector(".burger .top")
  const bot = menu.querySelector(".burger .bot")
  const mid = menu.querySelector(".burger .mid")
  const container = document.querySelector("#main")
  const buttonAnimation = gsap
    .timeline({
      paused: true,
      duration: 0.25,
      defaults: {
        ease: "power2.out",
      },
    })
    .to(mid, 0.2, { scale: 0.1, transformOrigin: "50% 50%" }, "burg")
    .add("rotate")
    .to(top, { y: 7, rotationZ: 45, transformOrigin: "50% 50%", stroke: "rgba(179, 150, 96, 1)" }, "rotate")
    .to(bot, { y: -5, rotationZ: -45, transformOrigin: "50% 50%", stroke: "rgba(179, 150, 96, 1)" }, "rotate")

  menu.querySelector(".burger").addEventListener("click", (e) => {
    e.preventDefault()
    gsap.to(window, { duration: 0.25, scrollTo: container })
    toggleMenu(undefined, buttonAnimation, container)
    container.appendChild(menuContainer)
    return false
  })
  container.insertBefore(menu, container.firstChild)

  gsap.fromTo(
    menu,
    { yPercent: -100 },
    {
      yPercent: 0,
      scrollTrigger: {
        trigger: container,
        scrub: true,
        start: `top center`,
        end: `top top`,
      },
    },
  )
}

let menutInited = false
function initMenuBar(force = false) {
  if (
    !force &&
    (!window.Stimulus ||
      window.Stimulus.controllers.filter((c) => c.modifiesDom && c.isReady).length !==
        document.querySelectorAll(".hcontainer").length ||
      menutInited)
  ) {
    return
  }

  menutInited = true

  document.querySelectorAll(".with-menu").forEach((container) => {
    const menu = menuTpl.cloneNode(true)
    menu.id = ""
    menu.style.display = "flex"
    menu.style.zIndex = 1000
    const top = menu.querySelector(".burger .top")
    const bot = menu.querySelector(".burger .bot")
    const mid = menu.querySelector(".burger .mid")
    const buttonAnimation = gsap
      .timeline({
        paused: true,
        duration: 0.25,
        defaults: {
          ease: "power2.out",
        },
      })
      .to(mid, 0.2, { scale: 0.1, transformOrigin: "50% 50%" }, "burg")
      .add("rotate")
      .to(top, { y: 7, rotationZ: 45, transformOrigin: "50% 50%", stroke: "rgba(179, 150, 96, 1)" }, "rotate")
      .to(bot, { y: -5, rotationZ: -45, transformOrigin: "50% 50%", stroke: "rgba(179, 150, 96, 1)" }, "rotate")

    menu.querySelector(".burger").addEventListener("click", (e) => {
      e.preventDefault()
      gsap.to(window, { duration: 0.25, scrollTo: container })
      toggleMenu(undefined, buttonAnimation, container)
      container.appendChild(menuContainer)
      return false
    })
    container.insertBefore(menu, container.firstChild)

    gsap.fromTo(
      menu,
      { yPercent: -100 },
      {
        yPercent: 0,
        scrollTrigger: {
          trigger: container,
          scrub: true,
          start: `top center`,
          end: `top top`,
        },
      },
    )
  })
}

if (window.matchMedia("(min-width: 1024px)").matches) {
  initMenuBar(document.body.dataset["menuBehaviour"] === "immediate")

  window.addEventListener("stimulus:connected", () => {
    initMenuBar()
  })
}

document.querySelectorAll("nav a").forEach((el) => {
  const href = el.getAttribute("href")
  el.addEventListener("click", (e) => {
    if (el.getAttribute("href").startsWith("#")) {
      e.preventDefault()
      toggleMenu(href)
    }
  })

  el.addEventListener("mouseover", () => {
    document.querySelectorAll("#menu-previews img").forEach((el) => (el.style.opacity = 0))
    document.querySelector(`#menu-previews img[data-menu-target="${href}"]`).style.opacity = 1
  })
})

