import { Controller } from "@hotwired/stimulus"
import gsap from "gsap"

export default class extends Controller {
  connect() {
    const cloud = this.element
    const visabilityIndex = document.getElementById("visabilityIndex")
    const scrub = {
      scrollTrigger: {
        trigger: this.element,
        scrub: true,
        end: "center center",
        toggleActions: "play none none none",
      },
      // ease: "none",
    }
    gsap.to(visabilityIndex, {
      ...scrub,
      scale: 1,
    })

    gsap.fromTo(
      "#viabilityAssuranceCurrency",
      {
        x: (_, item) => -item.clientWidth,
        y: (_, item) => item.parentNode.clientHeight,
      },
      {
        ...scrub,
        x: (_, item) => item.parentNode.clientWidth * 0.1,
        y: (_, item) => item.parentNode.clientHeight * 0.7,
      },
    )

    gsap.fromTo(
      "#singleFinanciaSpace",
      {
        x: (_, item) => -item.clientWidth,
        y: (_, item) => -item.clientHeight,
      },
      {
        ...scrub,
        x: (_, item) => item.parentNode.clientWidth * 0.05,
        y: (_, item) => item.parentNode.clientHeight * 0.15,
      },
    )

    gsap.fromTo(
      "#singleCostEquivalent",
      {
        x: () => cloud.clientWidth,
        y: () => -cloud.clientHeight / 2,
      },
      {
        ...scrub,
        x: (_, item) => cloud.clientWidth / 2 + item.clientWidth / 2,
        y: () => cloud.clientHeight / 2 - visabilityIndex.clientHeight,
      },
    )

    gsap.fromTo(
      "#influences",
      {
        x: () => -cloud.clientWidth,
        y: () => cloud.clientHeight / 10,
      },
      {
        ...scrub,
        x: "30%",
        y: "40%",
      },
    )

    gsap.fromTo(
      "#ocean",
      {
        x: (_, item) => -item.clientWidth,
        y: (_, item) => "25%",
      },
      {
        ...scrub,
        x: (_, item) => "50%",
        y: (_, item) => "10%",
      },
    )

    gsap.fromTo(
      "#forest",
      {
        x: () => cloud.clientWidth,
      },
      {
        ...scrub,
        x: (_, item) => cloud.clientWidth * 0.85 - item.width,
        y: (_, item) => cloud.clientHeight / 2,
      },
    )

    gsap.fromTo(
      "#land",
      {
        x: (_, item) => cloud.clientWidth,
        y: (_, item) => -200,
      },
      {
        ...scrub,
        x: (_, item) => (item.parentNode.clientWidth - item.clientWidth) * 0.8,
        y: (_, item) => "40%",
      },
    )

    gsap.fromTo(
      "#water",
      {
        x: (_, item) => -item.clientWidth,
        y: (_, item) => item.parentNode.clientHeight,
      },
      {
        ...scrub,
        x: (_, item) => "15%",
        y: (_, item) => "100%",
      },
    )

    gsap.fromTo(
      "#viabilityAssuranceCurrencyImg",
      {
        x: (_, item) => cloud.clientWidth,
        y: (_, item) => cloud.clientHeight,
      },
      {
        ...scrub,
        x: (_, item) => cloud.clientWidth / 2 + item.clientWidth,
        y: (_, item) => cloud.clientHeight / 2 - item.clientHeight / 4,
      },
    )

    gsap.fromTo(
      "#reserveFund",
      {
        x: (_, item) => (cloud.clientWidth - item.clientWidth) * 0.9,
        y: (_, item) => -item.clientHeight,
      },
      {
        ...scrub,
        y: "10%",
        scale: 0.8,
      },
    )

    this.parallaxContainers = this.element.querySelectorAll(".parallax-container")
    // this.zIndex = 0;

    this.parallaxContainers.forEach((item) => {
      this.zIndex++
      item.style.zIndex = this.zIndex
      item.dataset.parallaxSpeed = Math.floor(this.random(40, 120))
    })

    document.addEventListener("mousemove", (event) => {
      this.handleMouseMove(event)
    })
  }

  handleMouseMove(event) {
    const rect = this.element.getBoundingClientRect()

    if (event.clientY >= rect.top && event.clientY <= rect.bottom && this.parallaxContainers.length > 0) {
      const x = event.clientX - rect.left
      const y = event.clientY - rect.top

      this.parallaxContainers.forEach((item) => {
        gsap.to(item, {
          x: ((x - rect.width / 2) / rect.width) * (item.dataset.parallaxSpeed || 1),
          y: ((y - rect.height / 2) / rect.height) * (item.dataset.parallaxSpeed || 1),
          duration: 0.5,
        })
      })
    }
  }

  random(min, max) {
    return Math.random() * (max - min) + min
  }
}
