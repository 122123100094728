import { Controller } from "@hotwired/stimulus"
import {gsap} from "gsap";

export default class extends Controller {
  connect() {
    if (this.done) {
      return
    }
    this.done = true
    const text = this.element.innerHTML
    this.element.innerHTML = ""
    const span = document.createElement("span")
    span.innerHTML = text
    this.element.appendChild(span)
    const svg = document.createElementNS("http://www.w3.org/2000/svg", "svg")
    const rect = document.createElementNS("http://www.w3.org/2000/svg", "rect")
    rect.setAttribute("rx", "10px")
    svg.appendChild(rect)
    this.element.appendChild(svg)

    const tl = gsap
      .timeline()
      .to(rect, {
        fill: "rgba(179, 150, 96, 0)",
        duration: 0.25,
      })
      .to(
        gsap.utils.toArray([...span.children, span]),
        {
          color: "rgba(179, 150, 96, 1)",
          duration: 0.25,
        },
        "<",
      )
      .to(
        rect,
        {
          drawSVG: rect.getBoundingClientRect().width - parseFloat(rect.getAttribute("rx")) * 2,
          duration: 0.75,
          strokeWidth: 5,
          ease: "power2.out",
        },
        "<",
      )
      .pause()

    this.element.addEventListener("mouseenter", (event) => {
      tl.play()
    })

    this.element.addEventListener("mouseleave", (event) => {
      tl.reverse()
    })
  }
}
