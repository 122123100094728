import { Controller } from "@hotwired/stimulus"
import { Chart } from "chart.js/auto"

export default class extends Controller {
  rendered = false

  async connect() {
    new IntersectionObserver(
      (entries) => {
        if (entries[0].isIntersecting) {
          this.render()
        }
      },
      {
        root: null,
        rootMargin: "0px",
        threshold: 0.7,
      },
    ).observe(this.element)
  }

  async render() {
    if (this.rendered) return
    this.rendered = true
    const res = await fetch("/products/steadygrowth/chart.csv")
    const csv = (await res.text()).split("\n").map((l) => l.split(";").map((x) => parseFloat(x)))
    const steadygrowth = csv.map((x) => x[0] + 3.5)
    const other = csv.map((x) => x[1])
    const currencyFormatter = new Intl.NumberFormat(undefined, { style: "percent", maximumFractionDigits: 2 })

    const totalDuration = 4000
    const delayBetweenPoints = totalDuration / steadygrowth.length
    const previousY = (ctx) =>
      ctx.index === 0
        ? ctx.chart.scales.y.getPixelForValue(100)
        : ctx.chart.getDatasetMeta(ctx.datasetIndex).data[ctx.index - 1]?.getProps?.(["y"], true)?.y

    Chart.defaults.color = "rgba(255, 255, 255)"
    new Chart(this.element, {
      type: "line",
      data: {
        labels: steadygrowth.map((_, i) => "Week " + (i + 1)),
        datasets: [
          {
            label: "SteadyGrowth",
            data: steadygrowth,
            // borderColor: "rgba(179, 150, 96, 1)",
            borderColor: "rgba(179, 150, 96, 1)",
            backgroundColor: "rgba(179, 150, 96, .4)",
            pointBackgroundColor: "rgba(255, 255, 255, .8)",
            fill: true,
            borderWidth: 2,
            pointBorderWidth: 1,
            pointRadius: 3,
            cubicInterpolationMode: "monotone",
          },
          {
            label: "Competitors products",
            data: other,
            borderColor: "rgba(179, 150, 96, 1)",
            borderDash: [5, 5],
            fill: false,
            borderWidth: 2,
            pointStyle: false,
            cubicInterpolationMode: "monotone",
          },
        ],
      },
      options: {
        layout: {
          padding: 5,
        },
        plugins: {
          legend: {
            position: "top",
          },
          tooltip: {
            usePointStyle: true,
          },
        },
        responsive: true,
        maintainAspectRatio: false,
        interaction: {
          mode: "index",
          intersect: false,
        },
        stacked: false,
        scales: {
          y: {
            title: {
              display: true,
              text: "Profit",
              color: "white",
              padding: 20,
              font: {
                size: 16,
                weight: "bold"
              }
            },
            ticks: {
              // Include a dollar sign in the ticks
              callback: (value, index, ticks) => currencyFormatter.format(value / 100),
            },
            // min: 0,
            type: "linear",
            display: true,
            position: "left",
          },
          x: {
            title: {
              display: true,
              text: "Week",
              color: "white",
              padding: 20,
              font: {
                size: 16,
                weight: "bold"
              }
            },
            ticks: {
              // Include a dollar sign in the ticks
              callback: (value, index, ticks) => (value % 4 || !value ? "" : value),
            },
          },
        },
        animation: {
          x: {
            type: "number",
            easing: "linear",
            duration: delayBetweenPoints,
            from: NaN, // the point is initially skipped
            delay(ctx) {
              if (ctx.type !== "data" || ctx.xStarted) {
                return 0
              }
              ctx.xStarted = true
              return ctx.index * delayBetweenPoints
            },
          },
          y: {
            type: "number",
            easing: "linear",
            duration: delayBetweenPoints,
            from: previousY,
            delay(ctx) {
              if (ctx.type !== "data" || ctx.yStarted) {
                return 0
              }
              ctx.yStarted = true
              return ctx.index * delayBetweenPoints
            },
          },
        },
      },
    })
  }
}
