import { Controller } from "@hotwired/stimulus"
import { gsap } from "gsap"

function random(min, max) {
  return Math.random() * (max - min) + min
}

export default class extends Controller {
  connect() {
    this.element.addEventListener('submit', async (e) => {
      e.preventDefault()
console.log(this.element.getAttribute('action'))
      try {
        const res = await fetch(this.element.getAttribute('action'), {
          url: this.element.getAttribute('action'),
          method: 'POST',
          mode: 'no-cors',
          body: Object.fromEntries(new FormData(this.element)),
          headers: {
            'Content-Type': 'application/json'
          }
        })
        console.log(res)
        const json = await res.json()
        console.log(json)
        if (json.status === 'success') {
          this.element.classList.add('hidden')
          this.element.parent.querySelector('#success').classList.remove('hidden')
        }
      } catch (e) {
console.log(e)
      }
    })

  }
}
