import { Controller } from "@hotwired/stimulus"
import { gsap } from "gsap"

export default class extends Controller {
  connect() {
    gsap.fromTo(
      this.element,
      { filter: "grayscale(100%)" },
      {
        filter: "grayscale(0%)",
        scrollTrigger: {
          trigger: this.element,
          start: "top center",
          end: "bottom bottom",
          scrub: 1,
        },
      },
    )
  }
}
