import { Controller } from "@hotwired/stimulus"
import gsap from "gsap"

export default class extends Controller {
  static outlets = ["horizontal"]
  static targets = ["scroller"]
  static values = { speed: Number }

  connect() {
    gsap.to(this.scrollerTarget, {
      y: (_, item) => -item.clientWidth,
      duration: this.speedValue || 200,
      repeat: -1,
      yoyo: true,
      // scrollTrigger: {
      //   // containerAnimation: this.hasHorizontalOutlet ? this.horizontalOutlet.tween : undefined,
      //   trigger: this.element,
      //   toggleActions: "play pause resume reset",
      // },
    })
  }
}
