import { Controller } from "@hotwired/stimulus"
import gsap from "gsap"

export default class extends Controller {
  static outlets = ["horizontal"]
  static values = {
    target: String,
  }

  connect() {
    this.element.addEventListener('click', (e) => {
      e.preventDefault()
      gsap.to(window, { duration: 1, scrollTo: this.targetValue })
    })
  }
}
