import { Controller } from "@hotwired/stimulus"
import { gsap } from "gsap"

function random(min, max) {
  return Math.random() * (max - min) + min
}

export default class extends Controller {
  connect() {
    for (let i = 0; i < 20; i++) {
      const bubble = document.createElement("div")
      bubble.classList.add("bubble")
      bubble.style.left = `${Math.random() * 100}%`
      bubble.style.top = 0
      const scale = random(0.4, 1)

      bubble.style.transform = `scale(${scale})`
      bubble.dataset.scale = scale
      bubble.dataset.repeat = 0
      this.element.appendChild(bubble)

      gsap.fromTo(
        bubble,
        {
          y: (_, item) => {
            item.dataset.repeat++

            if (item.dataset.repeat > 4) {
              item.style.left = `${Math.random() * 100}%`
              const scale = random(0.4, 1)
              item.style.transform = `scale(${scale})`
              item.dataset.scale = scale

              return this.element.clientHeight
            } else if (item.dataset.repeat < 2) {
              const y = `${Math.random() * this.element.clientHeight * 3}`
              item.dataset.y = y
              return y
            } else {
              return item.dataset.y
            }
          },
        },
        {
          y: (_, item) => "-100%",
          duration: (_, item) => 60 * item.dataset.scale,
          repeat: -1,
          repeatRefresh: true,
          scrollTrigger: {
            trigger: this.element,
            start: "top 50%",
            end: "bottom 50%",
          },
        },
      )
    }
  }
}
