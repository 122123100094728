import { Controller } from "@hotwired/stimulus"
import gsap from "gsap"

export default class extends Controller {
  connect() {
    const tabLinks = document.querySelectorAll("#tabs > a")
    const tabs = document.getElementById("tabs")
    let tabsRunning = false
    gsap.fromTo(
      "#tabs-bg-text",
      { scale: 2, opacity: 0.1 },
      {
        scale: 1,
        opacity: 0.4,
        ease: "none",
        scrollTrigger: {
          trigger: this.element,
          start: "top center",
          end: "bottom bottom",
          scrub: true,
        },
      },
    )

    tabLinks.forEach((link) => {
      link.addEventListener("click", (e) => {
        e.preventDefault()
        if (tabsRunning) {
          return
        }

        tabsRunning = true

        const lastActive = document.getElementsByClassName("active-tab")[0]
        const lastActiveLink = tabs.getElementsByClassName("active")[0]
        if (!lastActiveLink) {
          return
        }
        lastActive.classList.remove("active-tab")
        const ghost = document.createElement("div")
        ghost.classList.add("border-2", "rounded-xl")
        ghost.style.position = "absolute"
        ghost.style.borderColor = "#123F63"
        ghost.style.top = lastActiveLink.offsetTop + "px"
        ghost.style.left = lastActiveLink.offsetLeft + "px"
        ghost.style.width = lastActiveLink.clientWidth + 4 + "px"
        ghost.style.height = lastActiveLink.clientHeight + 4 + "px"

        tabs.appendChild(ghost)

        tabLinks.forEach((link) => link.classList.remove("active"))

        const tab = document.querySelector(e.target.dataset.href)
        tab.classList.add("active-tab")

        const bgText = document.querySelector("#tabs-bg-text span")
        const bgTextNew = document.createElement("span")
        bgTextNew.innerHTML = link.innerHTML
        bgTextNew.style.opacity = 0
        document.querySelector("#tabs-bg-text > div").appendChild(bgTextNew)

        gsap.to(ghost, {
          left: link.offsetLeft,
          width: link.clientWidth + 4,
          duration: 0.7,
          ease: "back",
          onComplete: () => {
            link.classList.add("active")
            ghost.remove()
          },
        })
        gsap.to(bgText, {
          opacity: 0,
          duration: 0.5,
        })
        gsap.fromTo(
          bgTextNew,
          { scale: 2, opacity: 1 },
          {
            opacity: 0.8,
            duration: 1,
            scale: 1,
            onComplete: () => {
              bgText.remove()
              tabsRunning = false
            },
          },
        )

        gsap.to(lastActiveLink, {
          color: "rgba(179, 150, 96, 1)",
          duration: 0.5,
        })

        gsap.to(link, {
          color: "#003C55",
          duration: 0.5,
        })

        gsap.to(lastActive, {
          opacity: 0,
          duration: 0.5,
        })

        gsap.to(tab, {
          opacity: 1,
          duration: 1,
        })

        return false
      })
    })
  }
}
