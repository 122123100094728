import { Controller } from "@hotwired/stimulus"
import { gsap } from "gsap"

export default class extends Controller {
  modifiesDom = true
  isReady = false

  connect() {
    console.log("Horizontal Controller")
    if (!window.matchMedia("(min-width: 1024px)").matches) {
      this.isReady = true
      return
    }
    const scroller = this.element.children[0]
    const sections = gsap.utils.toArray(scroller.children)

    this.tween = gsap.to(sections, {
      ease: "none",
      x: (_, item) => {
        return -(scroller.clientWidth - sections[sections.length - 1].clientWidth)
      },
      scrollTrigger: {
        trigger: scroller,
        scrub: true,
        pin: true,
        invalidateOnRefresh: true,
      },
    })
    this.isReady = true
    console.log('Horizontal Controller is ready')
    window.dispatchEvent(new Event("stimulus:connected"))
  }
}
