import { Controller } from "@hotwired/stimulus"
import gsap from "gsap"

export default class extends Controller {
  static outlets = ["horizontal"]
  static targets = ["bglayer"]

  connect() {
    gsap.to(this.bglayerTarget, {
      scrollTrigger: {
        trigger: this.element,
        scrub: true,
        horizontal: true,
        containerAnimation: this.hasHorizontalOutlet ? this.horizontalOutlet.tween : undefined,
      },
      x: (i, target) => target.clientWidth,
      ease: "none",
    })
  }
}
