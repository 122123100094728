console.log("Vite ⚡️ Ruby")
import { gsap } from "gsap"
import { ScrollTrigger } from "gsap/ScrollTrigger"
import {ScrollToPlugin} from "gsap/ScrollToPlugin"
import { DrawSVGPlugin } from "gsap/DrawSVGPlugin";
import { registerControllers } from "stimulus-vite-helpers"

gsap.registerPlugin(ScrollTrigger, ScrollToPlugin, DrawSVGPlugin)

import "~/misc/menu"

import { Application } from "@hotwired/stimulus"
const application = (window.Stimulus = Application.start())
const controllers = import.meta.glob("~/controllers/**/*_controller.js", {eager: true})
console.log(controllers)
registerControllers(application, controllers)

